export const Routes = {
  SELECTFLEET: '/app/select-fleet',
  DASHBOARD: '/app/dashboard',
  OVERVIEW: '/app/dashboard/overview',
  DRIVERSTATS: '/app/dashboard/driver-details',
  INSURANCE: '/app/dashboard/insurance',
  SUPPORT: '/app/dashboard/support',
  SETTINGS: '/app/settings',
  ACCOUNTS: '/app/settings/accounts',
  MANAGERS: '/app/settings/managers',
  ADVANCE: '/app/settings/advanced',
  VALIDATORTOOL: '/app/settings/validator-tool',
  VEHICLES: '/app/dashboard/vehicles',
  INCIDENTS: '/app/dashboard/incidents',
  APP_STATUS: '/app/dashboard/app-status',
  EDIT_DRIVERS: '/app/dashboard/edit-drivers',
  ADD_DRIVERS: '/app/dashboard/add-drivers',
  DRIVERS_MANAGEMENT: '/app/dashboard/drivers-management',
  DRIVERS_PERFORMANCE: '/app/dashboard/drivers-performance',
};

export const Roles = {
  SYS_ADMIN: 'sys_admin',
  ADMIN: 'administrator',
  FLEET_MANAGER: 'fleet_manager',
  CUSTOMER_SUPPORT: 'customer_support',
};

const CommonRoutes = [
  Routes.DASHBOARD,
  Routes.OVERVIEW,
  Routes.DRIVERSTATS,
  Routes.INSURANCE,
  Routes.SETTINGS,
  Routes.ACCOUNTS,
  Routes.ADVANCE,
  Routes.VALIDATORTOOL,
  Routes.VEHICLES,
  Routes.INCIDENTS,
  Routes.APP_STATUS,
  Routes.EDIT_DRIVERS,
  Routes.DRIVERS_PERFORMANCE,
  Routes.DRIVERS_MANAGEMENT,
];

export const roles_enum = {
  sys_admin: {
    key: Roles.SYS_ADMIN,
    value: 'System Administrator',
    accesibleRoutes: [
      ...CommonRoutes,
      Routes.SELECTFLEET,
      Routes.MANAGERS,
      Routes.ADD_DRIVERS,
    ],
    defaultRoute: Routes.DASHBOARD,
  },
  administrator: {
    key: Roles.ADMIN,
    value: 'Administrator',
    accesibleRoutes: [...CommonRoutes, Routes.MANAGERS],
    defaultRoute: Routes.DASHBOARD,
  },
  fleet_manager: {
    key: Roles.FLEET_MANAGER,
    value: 'Fleet Manager',
    accesibleRoutes: [...CommonRoutes],
    defaultRoute: Routes.DASHBOARD,
  },
  claims_contact: {
    key: 'claims_contact',
    value: 'Claims Contact',
    accesibleRoutes: [...CommonRoutes],
    defaultRoute: Routes.DASHBOARD,
  },
  customer_support: {
    key: Roles.CUSTOMER_SUPPORT,
    value: 'Customer Support',
    accesibleRoutes: [
      Routes.DASHBOARD,
      Routes.SUPPORT,
      Routes.SETTINGS,
      Routes.ACCOUNTS,
      Routes.ADVANCE,
      Routes.VALIDATORTOOL,
    ],
    defaultRoute: Routes.SUPPORT,
  },
};
