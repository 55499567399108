import moment from 'moment';

export const getDayOfTheMonth = (day = 1, d = new Date()) => {
  d.setDate(day);
  return d;
};

export const isBetween = (
  targetDate,
  startDate,
  endDate,
  isInclusive = false,
) =>
  moment(targetDate).isBetween(
    moment(startDate),
    moment(endDate),
    undefined,
    isInclusive ? '[]' : '()',
  );

export const isoTimeDifferenceText = (startDateTime, endDateTime) => {
  const seconds = moment(endDateTime).diff(moment(startDateTime), 'seconds');

  if (seconds < 60) {
    return ''.concat(seconds, ' sec');
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return remainingSeconds === 0
    ? ''.concat(minutes, ' min')
    : ''.concat(minutes, ' min ').concat(remainingSeconds, ' sec');
};

export const isoTimeToUTCMilliSeconds = (isoTime) => Date.parse(isoTime);

export const isoTimeToSimplifiedTime = (
  isoDateTime,
  convertToLocalTimezone = true,
) =>
  convertToLocalTimezone
    ? moment(isoDateTime).format('h:mm a')
    : moment.parseZone(isoDateTime).format('h:mm a');

export const apiDateToShortDate = (
  apiDate,
  withYear = false,
  ignoreDayOfMonth = false,
) => {
  let dateFormat = withYear ? 'MMM D, YYYY' : 'MMM D';
  dateFormat = ignoreDayOfMonth ? 'MMM, YYYY' : dateFormat;
  return moment(apiDate).format(dateFormat);
};

export const formateDate = (date) => moment(date).format('YYYY-MM-DD');

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const time = `${hours}:${minutes} ${ampm}`;
  return time;
};
