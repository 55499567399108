import moment from 'moment';
import { formateDate, getDayOfTheMonth } from './date';

const INTERVAL_TYPES = {
  quarter: 'quarter',
  month: 'month',
  week: 'week',
  day: 'day',
};

const getQuarterDateRange = () => ({
  startDate: formateDate(moment().startOf('quarter').toDate()),
  endDate: formateDate(moment().endOf('quarter').toDate()),
  intervalType: INTERVAL_TYPES.quarter,
});

const getMonthDateRange = () => ({
  startDate: formateDate(getDayOfTheMonth(1)),
  endDate: formateDate(moment().endOf('month').toDate()),
  intervalType: 'month',
});

const getDefaultDateRange = (intervalType = INTERVAL_TYPES.month) => {
  const defaultData =
    intervalType === INTERVAL_TYPES.quarter
      ? getQuarterDateRange()
      : getMonthDateRange();

  try {
    const localState = JSON.parse(
      window.localStorage.getItem('FM_DATE_FILTER'),
    );
    if (
      localState &&
      localState.startDate &&
      intervalType === localState.intervalType
    ) {
      return localState;
    }
    return defaultData;
  } catch (e) {
    console.error('Error parsing date filter from local storage:', e);
    return defaultData;
  }
};

const handleDateSelection = (dt, interval_type) => {
  let start;
  let end;
  if (interval_type === INTERVAL_TYPES.week) {
    const startOfWeek = moment(dt).startOf('isoweek').toDate();
    const endOfWeek = moment(startOfWeek).endOf('isoweek').toDate();
    start = formateDate(startOfWeek);
    end = formateDate(endOfWeek);
  } else if (interval_type === INTERVAL_TYPES.month) {
    const startOfMonth = moment(dt).startOf('month').toDate();
    const endOfMonth = moment(dt).endOf('month').toDate();
    start = formateDate(startOfMonth);
    end = formateDate(endOfMonth);
  } else if (interval_type === INTERVAL_TYPES.quarter) {
    const startOfQuarter = moment(dt).startOf('quarter').toDate();
    const endOfQuarter = moment(dt).endOf('quarter').toDate();
    start = formateDate(startOfQuarter);
    end = formateDate(endOfQuarter);
  } else {
    const startOfDay = moment(dt).startOf('day').toDate();
    const endOfDay = moment(dt).endOf('day').toDate();
    start = formateDate(startOfDay);
    end = formateDate(endOfDay);
  }
  return {
    start,
    end,
  };
};

const getLastPeriodDates = (start_date, interval_type) => {
  const dt = moment(start_date).clone();
  if (interval_type === INTERVAL_TYPES.week) {
    const lastValidWeekStart = dt.startOf('isoweek').subtract(7, 'days');
    return {
      startDate: lastValidWeekStart.toDate(),
      // endDate:lastValidWeekStart
    };
  }
  if (interval_type === INTERVAL_TYPES.month) {
    return {
      startDate: dt.subtract(1, 'months').toDate(),
    };
  }

  if (interval_type === INTERVAL_TYPES.quarter) {
    return {
      startDate: dt.startOf('quarter').subtract(1, 'quarters').toDate(),
    };
  }
  return {
    startDate: dt.startOf('day').subtract(1, 'days').toDate(),
  };
};

const clearFilters = () => {
  window.localStorage.removeItem('FM_DATE_FILTER');
};
export {
  INTERVAL_TYPES,
  clearFilters,
  getDefaultDateRange,
  getLastPeriodDates,
  getQuarterDateRange,
  handleDateSelection,
};
